import { Controller } from "@hotwired/stimulus"
import Tab from 'bootstrap/js/dist/tab'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.nav .nav-link').forEach(el => {
      const tabTrigger = new Tab(el)
      el.addEventListener('click', e => {
        e.preventDefault()
        tabTrigger.show()
      })
    })
  }
}
