import { Controller } from "@hotwired/stimulus"
import Tooltip from 'bootstrap/js/dist/tooltip'

export default class extends Controller {
    static values = {
        timeout: Number
    }

    connect() {
        if (this.timeoutValue > 0) {
            this.timeout = window.setTimeout(() => this.element.click(), this.timeoutValue)
        }
    }

    disconnect() {
        window.clearTimeout(this.timeout)
    }
}
