import { Controller } from "@hotwired/stimulus"
import Tooltip from 'bootstrap/js/dist/tooltip'

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    this.tooltipTargets.forEach(e => new Tooltip(e))
  }
}
