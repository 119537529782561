import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  connect() {
    const fragment = window.location.hash
    const element = window.document.getElementById(fragment.substring(1))

    element.classList.add('search-match')

    // Add .show class to all parent elements that are .collapse elements
    let parent = element.parentElement
    while (parent) {
      if (parent.classList.contains('collapse')) {
        parent.classList.add('show')
      }
      parent = parent.parentElement
    }
  }
}
