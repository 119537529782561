import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
export default class extends Controller {
  static values = {
    series: Array,
    labels: Array,
    sparkline: Boolean,
    width: String,
    height: String
  }

  connect() {
    const options = {
      series: this.seriesValue,
      chart: {
        width: this.widthValue,
        height: this.heightValue,
        type: this.sparklineValue ? "line": "bar",
        zoom: { enabled: false },
        sparkline: { enabled: this.sparklineValue },
        locales: [{
          name: "gl",
          options: {
            months: ["Xaneiro", "Febreiro", "Marzo", "Abril", "Maio", "Xuño", "Xullo", "Agosto", "Setembro", "Outubro", "Novembro", "Decembro"],
            shortMonths: ["Xan", "Feb", "Mar", "Abr", "Mai", "Xuñ", "Xul", "Ago", "Set", "Out", "Nov", "Dec"],
            days: ["Domingo", "Luns", "Martes", "Mércores", "Xoves", "Venres", "Sábado"],
            shortDays: ["Dom", "Lun", "Mar", "Mér", "Xov", "Ven", "Sáb"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Selección",
              selectionZoom: "Selección zoom",
              zoomIn: "Aumentar zoom",
              zoomOut: "Disminuir zoom",
              pan: "Desplazar",
              reset: "Restablecer zoom"
            }
          }
        }],
        defaultLocale: "gl"
      },
      dataLabels: { enabled: false },
      stroke: {
        curve: "straight",
        width: 2,
      },
      grid: {
        row: {
          colors: this.sparklineValue ? ["transparent"] : ["#f3f3f3", "transparent"],
          opacity: 0.5
        },
      },
      markers: { size: 0 },
      xaxis: { categories: this.labelsValue },
      colors: [
        "#1f78b4",
        "#b2df8a",
        "#33a02c",
        "#fb9a99",
        "#e31a1c",
        "#fdbf6f",
        "#ff7f00",
        "#cab2d6",
        "#6a3d9a",
        "#a6cee3"
      ]
    }
    this.chart = new ApexCharts(this.element, options)
    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
  }
}
